// src/axiosConfig.js
import axios from "axios";

const Axios = axios.create({
  baseURL: "https://lending-admin.wolfpack.com/api", // Replace with your base URL
});

// Add a request interceptor to include the Bearer token in the headers
Axios.interceptors.request.use(
  (config) => {
    const admin = JSON.parse(localStorage.getItem("user")); // Assuming the token is stored in localStorage

    if (admin) {
      config.headers.Authorization = `Bearer ${admin.accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem("user"); // Optionally remove the user token
      window.location.href = "/login"; // Redirect to the login page
    }
    return Promise.reject(error);
  }
);

export default Axios;
