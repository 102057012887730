import React, { Fragment, useEffect, useState } from "react";
import { Row, Col, Card, Button } from "react-bootstrap";
import { CommonDataTable } from "../../../components/CommonDataTable";
import { useDispatch, useSelector } from "react-redux";

import SearchInput from "../../../components/Forms/SearchInput";
import { Grid } from "@mui/material";
import useDialogs from "../../../hooks/useDialogs";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { getStockWise } from "../../../Redux/api";
import SearchIcon from "@mui/icons-material/Search";
import { currencyFormate } from "../../../Utils/utils";
import TotalStocks from "../../Stocks/TotalStocks";
import TotalDashboardStock from "./TotalDashboardStock";

function StockTable() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { stockData, backUrl } = useSelector((state) => state.dashboard);
  const { openDialog, closeDialog, isDialogOpen } = useDialogs();
  const { role } = useSelector((state) => state.auth);

  const [searchParams] = useSearchParams();
  const value = searchParams.get("value");
  const name = decodeURIComponent(searchParams.get("name"));
  const type = searchParams.get("type");
  const sector_id = searchParams.get("sector_id");

  // ** State managements
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState("");
  const [allocation, setAllocation] = useState([]);

  const [search, setSearch] = useState("");

  // ** Table Headers
  const columns = [
    {
      name: "Rank",
      cell: (row, index) => <div>{row.id !== "total" && index + 1}</div>,
      width: "100px",
      center: true,
    },
    {
      name: "Stocks",
      selector: (row) => [row.stock],
      cell: (row) => <div>{row.stock_name}</div>,
      sortable: true,
      left: false,
    },
    {
      name: "Portfolio Value",
      selector: (row) => [row.total_portfolio_value],
      cell: (row) => <div>{currencyFormate(row.total_portfolio_value)}</div>,
      sortable: true,
      right: true,
    },

    {
      name: "% of Sector",
      selector: (row) => [row.percentSector],
      cell: (row) => (
        <div>
          {" "}
          {((row.total_portfolio_value / value) * 100).toFixed(2)}
        </div>
      ),
      width: "10%",
      sortable: true,
      right: true,
    },
    {
      name: "Loan Book Value",
      selector: (row) => [row.loanBookValue],
      cell: (row) => <div>{currencyFormate(row.loan_book_value)}</div>,
      width: "12%",
      sortable: true,
      right: true,
    },
    {
      name: "LVR%",
      selector: (row) => [row.id !== "total" ? row.lvr : row.totalLvr],
      sortable: true,
      right: true,
      width: "150px",
      cell: (row) => (
        <div>
          {(row.loan_book_value / row.total_portfolio_value)?.toFixed(2)}
        </div>
      ),
    },
    {
      name: "# Loans",
      selector: (row) => [
        row.id !== "total" ? row.total_loans : row.totalnumLoans ?? 0,
      ],
      sortable: true,
      right: true,
      width: "200px",
      cell: (row) => (
        <div>
          {row.id !== "total" ? row.total_loans : row.totalnumLoans ?? 0}
        </div>
      ),
    },
  ];

  useEffect(() => {
    dispatch(getStockWise({
      rowsPerPage,
      page,
      search,
      sector_id,
    }))
      .unwrap()
      .then((response) => {
        // Handle the successful response
        //setData(response);
        setAllocation(response.totalOfAllData); // Your allocation logic
      })
      .catch((error) => {
        // Handle any errors
        console.error("Error fetching data: ", error);
      });
    // eslint-disable-next-line
  }, [page, rowsPerPage, search, type]);

  return (
    <Fragment>
      <div
        style={{
          position: "relative", // Needed for positioning the circles
          paddingBottom: 10,
          height: "60px",
          backgroundColor: "var(--primary-bg-hover)",
          borderRadius: 10,
          overflow: "hidden",
        }}
        className="mt-4 px-4 py-3 d-flex align-items-center justify-content-between circle-container"
      >
        <Link
          //href="dashboard"
          style={{ alignItems: "center" }}
          className="d-flex text-white tx-20"
        >
          <Link className="d-flex text-white tx-20" to={"/dashboard"}>
            <u>Dashboard </u>
          </Link>
          <span className="mx-2"> / </span>{" "}
          <Link className="d-flex text-white tx-20" onClick={(e) => {
        e.preventDefault(); // Prevent default link behavior
        navigate(-1); // Go back one step in the browser history
      }}>
            {/* <u>{name}</u> */}
            <u>Sectors</u>
          </Link>
          <span className="mx-2"> / </span>Stocks
        </Link>
        <div className="d-flex align-items-center justify-content-between">
          <div
            style={{
              marginRight: 15,
              backgroundColor: "rgba(255,255,255,0.2)",
              padding: 10,
              borderRadius: 10,
            }}
          >
            <svg
              width="24"
              height="23"
              viewBox="0 0 24 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.116755 20.9416C0.203512 21.391 0.612443 21.8253 1.05889 21.9241C1.43504 22.0016 1.81822 22.0395 2.20221 22.0371C2.58634 22.0392 2.96965 22.0014 3.34599 21.9241C3.79338 21.8253 4.20137 21.3891 4.28813 20.9416C4.34299 20.6678 4.41006 19.9064 4.40443 19.3992C4.41006 18.8943 4.34299 18.1287 4.28813 17.8572C4.20137 17.4078 3.79338 16.9735 3.34599 16.8742C2.96965 16.797 2.58634 16.7592 2.20221 16.7613C1.81823 16.7592 1.43507 16.797 1.05889 16.8742C0.612443 16.9735 0.203512 17.4078 0.116755 17.8572C0.0618873 18.1311 -0.00517356 18.8952 0.000453926 19.3992C-0.00517356 19.9046 0.0618873 20.6678 0.116755 20.9416Z"
                fill="white"
              />
              <path
                d="M13.18 20.9416C13.2672 21.391 13.6761 21.8253 14.1226 21.9241C14.8774 22.0747 15.6544 22.0747 16.4092 21.9241C16.8561 21.8253 17.2651 21.391 17.3518 20.9416C17.4062 20.6678 17.4738 18.3814 17.4681 17.8765C17.4738 17.3716 17.4062 15.0852 17.3518 14.8114C17.2651 14.3625 16.8561 13.9282 16.4092 13.8289C15.6544 13.6783 14.8774 13.6783 14.1226 13.8289C13.6761 13.9282 13.2672 14.3625 13.18 14.8114C13.1256 15.0852 13.0581 17.3716 13.0637 17.8765C13.0581 18.3814 13.1256 20.6678 13.18 20.9416Z"
                fill="white"
              />
              <path
                d="M6.64849 20.9417C6.73525 21.391 7.14418 21.8253 7.59109 21.9242C8.34592 22.0748 9.12291 22.0748 9.87773 21.9242C10.3242 21.8253 10.7331 21.391 10.8203 20.9417C10.8747 20.6678 10.9423 16.8587 10.9366 16.3538C10.9423 15.8489 10.8747 12.0399 10.8203 11.766C10.7331 11.3167 10.3242 10.8823 9.87773 10.7831C9.12284 10.6331 8.34598 10.6331 7.59109 10.7831C7.14418 10.8823 6.73525 11.3167 6.64849 11.766C6.59409 12.0399 6.52656 15.8489 6.53219 16.3538C6.52656 16.8587 6.59409 20.6678 6.64849 20.9417Z"
                fill="white"
              />
              <path
                d="M19.7122 20.9417C19.799 21.3911 20.2079 21.8254 20.6543 21.9242C21.4093 22.0748 22.1865 22.0748 22.9415 21.9242C23.3879 21.8254 23.7968 21.3911 23.8836 20.9417C23.9385 20.6679 24.0055 14.858 23.9999 14.3541C24.0055 13.8492 23.9385 8.03937 23.8836 7.76645C23.7968 7.31708 23.3879 6.88277 22.9415 6.78348C22.1864 6.63353 21.4094 6.63353 20.6543 6.78348C20.2079 6.88277 19.799 7.31708 19.7122 7.76645C19.6573 8.04031 19.5903 13.8501 19.5959 14.3541C19.5903 14.858 19.6573 20.6679 19.7122 20.9417Z"
                fill="white"
              />
              <path
                d="M21.7981 0.0372317C21.5044 0.0370824 21.215 0.107726 20.9542 0.243218C20.6933 0.37871 20.4688 0.575085 20.2994 0.815815C20.1299 1.05655 20.0206 1.33459 19.9806 1.62654C19.9407 1.91849 19.9711 2.21581 20.0695 2.49348L15.9896 7.13918C15.7036 7.01611 15.391 6.96812 15.0814 6.99973C14.7718 7.03134 14.4753 7.14151 14.2198 7.31987L10.5539 5.04431C10.4954 4.58021 10.2628 4.15592 9.90363 3.85769C9.54443 3.55946 9.08552 3.40968 8.6202 3.43881C8.15488 3.46793 7.71808 3.67377 7.3986 4.01448C7.07912 4.35519 6.90094 4.80519 6.9003 5.27299C6.9003 5.32005 6.90264 5.36993 6.90639 5.41745L2.64733 9.98504C2.50173 9.94884 2.35229 9.93051 2.20229 9.93045C1.81532 9.93066 1.43834 10.0537 1.1253 10.282C0.812269 10.5102 0.579228 10.832 0.45954 11.2012C0.339853 11.5705 0.339654 11.9682 0.458973 12.3376C0.578292 12.7069 0.811012 13.029 1.12382 13.2575C1.43663 13.4861 1.81349 13.6095 2.20045 13.6101C2.58742 13.6107 2.96465 13.4885 3.27815 13.2608C3.59165 13.0332 3.82535 12.7119 3.94579 12.3429C4.06624 11.9739 4.06725 11.5762 3.94868 11.2066L7.93482 6.92931C8.2439 7.07905 8.58865 7.13877 8.92988 7.10167C9.2711 7.06457 9.5951 6.93215 9.86504 6.71945L13.4338 8.93478C13.4623 9.41201 13.6747 9.8594 14.0261 10.1822C14.3776 10.5051 14.8404 10.678 15.3167 10.6644C15.793 10.6508 16.2453 10.4518 16.5779 10.1095C16.9105 9.76717 17.0973 9.3084 17.0987 8.83032C17.0987 8.75259 17.0937 8.67495 17.0837 8.59787L21.407 3.67549C21.6585 3.73059 21.9188 3.73194 22.1709 3.67944C22.423 3.62695 22.6612 3.52179 22.8701 3.37081C23.0791 3.21983 23.2539 3.02641 23.3834 2.80314C23.5129 2.57986 23.5941 2.33173 23.6217 2.07489C23.6493 1.81805 23.6227 1.55826 23.5437 1.31241C23.4646 1.06656 23.3349 0.840172 23.1628 0.647965C22.9908 0.455757 22.7804 0.302041 22.5453 0.196817C22.3102 0.0915933 22.0556 0.0372189 21.7981 0.0372317Z"
                fill="white"
              />
            </svg>
          </div>

          <p className="text-white align-item-center mb-0 tx-20 ">
            {name}: <span className="h4">{currencyFormate(value)}</span>
          </p>
        </div>
      </div>

      <Row className="row-sm mt-3">
        <Col sm={12} lg={12}>
          <Row className="row-sm">
            <Col sm={12} lg={12} xl={12}>
              <Card className="custom-card overflow-hidden">
                <Card.Body className="ps-12">
                  <Grid
                    container
                    justifyContent="space-between"
                    spacing={2}
                    mb={4}
                    // border={1}
                    alignItems="center"
                  >
                    <Grid item xs={12} sm={6} md={3} lg={3} display="flex">
                      <SearchInput
                        style={{
                          borderTopRightRadius: "0px",
                          borderBottomRightRadius: "0px",
                        }}
                        onChange={setSearch}
                      />
                      <Button
                        variant="primary"
                        type="button"
                        style={{
                          borderTopLeftRadius: "0px",
                          borderBottomLeftRadius: "0px",
                        }}
                        className="btn btn-primary btn-icon-text"
                        onClick={() =>
                          dispatch(
                            getStockWise({
                              rowsPerPage,
                              page,
                              search,
                              sector_id,
                            })
                          )
                        }
                      >
                        <SearchIcon sx={{ color: "white", fontSize: "25px" }} />
                        {/* <i className="fe fe-plus me-2"></i> */}
                      </Button>
                    </Grid>
                    <Grid gap="20px" display="flex">
                      <Button
                        variant="primary"
                        type="button"
                        className="btn my-2 btn-icon-text mx-2"
                        onClick={() => openDialog("TotalDashboardStock")}
                      >
                        <svg width="16" height="16" style={{ marginRight: "6px" }} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.1824 6.22883H8.27542C7.97031 6.22883 7.71729 5.9758 7.71729 5.67069C7.71729 5.36557 7.97031 5.11255 8.27542 5.11255H12.1824C12.4875 5.11255 12.7405 5.36557 12.7405 5.67069C12.7405 5.9758 12.495 6.22883 12.1824 6.22883Z" fill="white" />
                          <path d="M4.36805 6.79447C4.22665 6.79447 4.08526 6.74238 3.97363 6.63075L3.41549 6.07259C3.19968 5.85678 3.19968 5.49957 3.41549 5.28375C3.63131 5.06794 3.98852 5.06794 4.20433 5.28375L4.36805 5.44748L5.64805 4.16748C5.86386 3.95166 6.22107 3.95166 6.43689 4.16748C6.65272 4.38329 6.65272 4.7405 6.43689 4.95631L4.76247 6.63075C4.65828 6.73494 4.51689 6.79447 4.36805 6.79447Z" fill="white" />
                          <path d="M12.1824 11.4383H8.27542C7.97031 11.4383 7.71729 11.1853 7.71729 10.8802C7.71729 10.575 7.97031 10.322 8.27542 10.322H12.1824C12.4875 10.322 12.7405 10.575 12.7405 10.8802C12.7405 11.1853 12.495 11.4383 12.1824 11.4383Z" fill="white" />
                          <path d="M4.36805 12.0037C4.22665 12.0037 4.08526 11.9516 3.97363 11.84L3.41549 11.2818C3.19968 11.066 3.19968 10.7088 3.41549 10.493C3.63131 10.2772 3.98852 10.2772 4.20433 10.493L4.36805 10.6567L5.64805 9.3767C5.86386 9.16089 6.22107 9.16089 6.43689 9.3767C6.65272 9.59252 6.65272 9.94973 6.43689 10.1655L4.76247 11.84C4.65828 11.9441 4.51689 12.0037 4.36805 12.0037Z" fill="white" />
                          <path d="M10.2326 16H5.76744C1.72651 16 0 14.2735 0 10.2326V5.76744C0 1.72651 1.72651 0 5.76744 0H10.2326C14.2735 0 16 1.72651 16 5.76744V10.2326C16 14.2735 14.2735 16 10.2326 16ZM5.76744 1.11628C2.33674 1.11628 1.11628 2.33674 1.11628 5.76744V10.2326C1.11628 13.6633 2.33674 14.8837 5.76744 14.8837H10.2326C13.6633 14.8837 14.8837 13.6633 14.8837 10.2326V5.76744C14.8837 2.33674 13.6633 1.11628 10.2326 1.11628H5.76744Z" fill="white" />
                        </svg> Summary
                      </Button>
                      {role === "SUPER_ADMIN" && (
                        <Grid item>
                          <Button
                            variant="primary"
                            type="button"
                            className="btn my-2 btn-icon-text mx-2 "
                          >
                            <i className="fe fe-download-cloud me-2"></i> Download
                            Report
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <div>
                    <CommonDataTable
                      setPage={setPage}
                      setRowsPerPage={setRowsPerPage}
                      data={stockData.data}
                      totalRows={stockData.totalRows}
                      columns={columns}
                      setSearch={setSearch}
                      page={page}
                      rowsPerPage={rowsPerPage}
                      sortServer= {true}
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      {
    isDialogOpen("TotalDashboardStock") && (
      <TotalDashboardStock
        data={data}
        open={isDialogOpen("TotalDashboardStock")}
        onClose={() => closeDialog("TotalDashboardStock")}
        allocation={allocation}
      />
    )
  }
    </Fragment>
  );
}

export default StockTable;

