import { CircularProgress } from "@mui/material";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import "react-data-table-component-extensions/dist/index.css";
import { useSelector } from "react-redux";
// Custom styles for the DataTable
const customStyles = {
  headCells: {
    style: {
      fontSize: "12px",
      whiteSpace: "wrap", // Prevent text from wrapping
      overflow: "hidden",
      color: "#F2674A",
      fontWeight: 800,
      backgroundColor: "#F2674A1A",
      border: "none",
    },
  },
  cells: {
    style: {
      whiteSpace: "nowrap", // Prevent text from wrapping in cells
      overflow: "hidden",
      textOverflow: "ellipsis",
      border: "none",
      color: "white",
    },
  },
  pagination: {
    style: {
      color: "white",
      backgroundColor: "#F2674A1A",
      border: "none",
    },
  },
  noData: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "white",
      backgroundColor: "#141414",
      border: "none",
    },
  },
  progress: {
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "#F2674A",
      backgroundColor: "#141414",
      border: "none",
    },
  },
};

export function CommonDataTable({
  data,
  totalRows,
  setPage,
  setRowsPerPage,
  columns,
  onRowClicked,
  sortByKey,
  sortServer,
  ...props
}) {
  const { isLoading } = useSelector((state) => state.loading);
  const conditionalRowStyles = [
    {
      when: (row) => row.id === "total",
      style: {
        backgroundColor: "#f1f1f1",
        fontWeight: "bold",
      },
    },
  ];

  const [sortedField, setSortedField] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const handleSort = (column) => {
    const newDirection =
      sortedField === column.selector && sortDirection === "asc" ? "desc" : "asc";

    setSortDirection(newDirection);
    setSortedField(column.selector);

    if (sortServer) {
      sortByKey(column.selector, newDirection);
    } else {
      const sortedData = customSort(data, column.selector, newDirection);
      console.log("Client-Sorted Data:", sortedData);
    }
  };

  const customSort = (rows, field, direction) => {
    const multiplier = direction !== "asc" ? 1 : -1;
    return rows.sort((a, b) => {
      if (a[field] < b[field]) return -1 * multiplier;
      if (a[field] > b[field]) return 1 * multiplier;
      return 0;
    });
  };

  return (
    <div>
      <DataTable
        columns={columns}
        data={data}
        pagination
        paginationServer
        fixedHeaderScrollHeight="600px"
        conditionalRowStyles={conditionalRowStyles}
        onChangePage={(value) => {
          setPage(value);
        }}
        responsive={true}
        fixedHeader
        theme="dark"
        onChangeRowsPerPage={setRowsPerPage}
        paginationTotalRows={totalRows}
        onRowClicked={onRowClicked}
        progressPending={isLoading}
        customStyles={customStyles}
        sortServer
        onSort={handleSort}
        sortDirection={sortDirection}
        progressComponent={<CircularProgress size="20px" color="primary" />}
        {...props}
      />
    </div>
  );
}
