import { Grid } from "@mui/material";
import React from "react";
import CommonTotalDialog from "../../components/CommonTotalDialog";
import { currencyFormate } from "../../Utils/utils";
import { Margin } from "@mui/icons-material";


function TotalSector({ open, onClose, allocation, totalLendingCapacity }) {

  return (
    <CommonTotalDialog

      open={open}
      onClose={onClose}
      // maxWidth="100px"
      maxWidth="sm"  // Set the maximum width, options: 'xs', 'sm', 'md', 'lg', 'xl'
      fullWidth
      title={
        <>
         
          <svg width="24" height="24" style={{marginRight:"6px"}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.1824 6.22883H8.27542C7.97031 6.22883 7.71729 5.9758 7.71729 5.67069C7.71729 5.36557 7.97031 5.11255 8.27542 5.11255H12.1824C12.4875 5.11255 12.7405 5.36557 12.7405 5.67069C12.7405 5.9758 12.495 6.22883 12.1824 6.22883Z" fill="white"/>
<path d="M4.36805 6.79447C4.22665 6.79447 4.08526 6.74238 3.97363 6.63075L3.41549 6.07259C3.19968 5.85678 3.19968 5.49957 3.41549 5.28375C3.63131 5.06794 3.98852 5.06794 4.20433 5.28375L4.36805 5.44748L5.64805 4.16748C5.86386 3.95166 6.22107 3.95166 6.43689 4.16748C6.65272 4.38329 6.65272 4.7405 6.43689 4.95631L4.76247 6.63075C4.65828 6.73494 4.51689 6.79447 4.36805 6.79447Z" fill="white"/>
<path d="M12.1824 11.4383H8.27542C7.97031 11.4383 7.71729 11.1853 7.71729 10.8802C7.71729 10.575 7.97031 10.322 8.27542 10.322H12.1824C12.4875 10.322 12.7405 10.575 12.7405 10.8802C12.7405 11.1853 12.495 11.4383 12.1824 11.4383Z" fill="white"/>
<path d="M4.36805 12.0037C4.22665 12.0037 4.08526 11.9516 3.97363 11.84L3.41549 11.2818C3.19968 11.066 3.19968 10.7088 3.41549 10.493C3.63131 10.2772 3.98852 10.2772 4.20433 10.493L4.36805 10.6567L5.64805 9.3767C5.86386 9.16089 6.22107 9.16089 6.43689 9.3767C6.65272 9.59252 6.65272 9.94973 6.43689 10.1655L4.76247 11.84C4.65828 11.9441 4.51689 12.0037 4.36805 12.0037Z" fill="white"/>
<path d="M10.2326 16H5.76744C1.72651 16 0 14.2735 0 10.2326V5.76744C0 1.72651 1.72651 0 5.76744 0H10.2326C14.2735 0 16 1.72651 16 5.76744V10.2326C16 14.2735 14.2735 16 10.2326 16ZM5.76744 1.11628C2.33674 1.11628 1.11628 2.33674 1.11628 5.76744V10.2326C1.11628 13.6633 2.33674 14.8837 5.76744 14.8837H10.2326C13.6633 14.8837 14.8837 13.6633 14.8837 10.2326V5.76744C14.8837 2.33674 13.6633 1.11628 10.2326 1.11628H5.76744Z" fill="white"/>
</svg> Summary
        </>
      }
    >

      <Grid md={12} mt={3} mx={2} style={{ display: "flex", justifyContent: 'space-between' }}>

        {/* <Grid item sm={12} md={12} style={{width:"fit-content"}}>
          <p className="mb-1" style={{ color: "#F2674A",width:"fit-content"}}>
            Allocation
          </p>
          <div style={{width:"fit-content"}}>
            <p style={{ color: "white",width:"fit-content"}}>
            {currencyFormate(allocation.totalLvrInAmount)}
            </p>
            <div style={{ color: "#4A7AF2", fontWeight: "500",width:"fit-content"}}>
            ({allocation.totalLvr.toFixed(2)}%)
            </div>
          </div>
        </Grid>
        <Grid item sm={12} md={12} style={{width:"fit-content"}}>
          <div className="mb-2 fit-content-width" style={{ color: "#F2674A",width:"fit-content"}}>
          Available
          </div>
          <div style={{width:"fit-content"}}>
            <div className="fit-content-width" style={{ color: "red", fontWeight: "500",width:"fit-content"}}>
            {currencyFormate(allocation.totalAvailableAmount)}
            </div>
            <div className="fit-content-width" style={{ color: "#4A7AF2", fontWeight: "500",width:"fit-content"}}>
            ({allocation.totalAvailableAmountInPer.toFixed(2)}%)
            </div>
          </div>
        </Grid>
        <Grid item sm={12} md={12} style={{width:"fit-content"}}>
          <div className="mb-2" style={{ color: "#F2674A",width:"fit-content"}}>
            Invested
          </div>
          <div style={{width:"fit-content"}}>
            <div style={{ color: "green", fontWeight: "500",width:"fit-content" }}>
            {currencyFormate(allocation.totalInvestedAmount)}
            </div>
            <div style={{ color: "#4A7AF2", fontWeight: "500",width:"fit-content" }}>
            ({allocation.totalInvestedAmountInPer.toFixed(2)}%)
            </div>
          </div>
        </Grid> */}
        <div>
          <p className="mb-1" style={{ color: "#F2674A", width: "fit-content" }}>
            Allocation
          </p>
          <div style={{ width: "fit-content" }}>
            <p style={{ color: "white", width: "fit-content" }}>
              {currencyFormate(allocation.totalLvrInAmount)}
            </p>
            <div style={{ color: "#4A7AF2", fontWeight: "500", width: "fit-content" }}>
              ({allocation.totalLvr.toFixed(2)}%)
            </div>
          </div>
        </div>
        <div>
          <p className="mb-2 fit-content-width" style={{ color: "#F2674A", width: "fit-content" }}>
            Available
          </p>
          <div style={{ width: "fit-content" }}>
            <div className="fit-content-width" style={{ color: "red", fontWeight: "500", width: "fit-content" }}>
              {/* {currencyFormate(allocation.totalAvailableAmount)} */}
              {currencyFormate(totalLendingCapacity)}
            </div>
            <div className="fit-content-width" style={{ color: "#4A7AF2", fontWeight: "500", width: "fit-content" }}>
              {/* ({((allocation.totalAvailableAmount / allocation.totalLvrInAmount)*100).toFixed(2) }%) */}
              ({100}%)
            </div>
          </div>
        </div>
        <div>
        <p className="mb-2" style={{ color: "#F2674A",width:"fit-content"}}>
            Invested
          </p>
          <div style={{width:"fit-content"}}>
            <div style={{ color: "green", fontWeight: "500",width:"fit-content" }}>
            {currencyFormate(allocation.totalInvestedAmount)}
            </div>
            <div style={{ color: "#4A7AF2", fontWeight: "500",width:"fit-content" }}>
            ({((allocation.totalInvestedAmount / allocation.totalLvrInAmount)*100).toFixed(2)}%)
            </div>
          </div>
          </div>
      </Grid >
      <Grid md={8} mt={3} mx={2} style={{ display: "flex", gap: "100px" }}>
        <Grid item sm={12} md={12}>
          <div className="mb-2" style={{ color: "#F2674A",width:"fit-content"  }}>
            Reserved
          </div>
          <div style={{ color: "white", fontWeight: "500",width:"fit-content"  }}>
            {currencyFormate(allocation.totalPartialAmount)}
          </div>

        </Grid>
        <Grid item sm={12} md={12}>
          <div className="mb-2" style={{ color: "#F2674A",width:"fit-content"  }}>
            Portfolio
          </div>
          <div style={{ color: "white", fontWeight: "500",width:"fit-content"  }}>
            {allocation.totalGlobalInvestedInPer.toFixed(2)}%
          </div>

        </Grid>
      </Grid>

    </CommonTotalDialog>

  );
}

export default TotalSector;
